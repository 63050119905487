export const REGIONS = ['Reykjavík 1', 'Reykjavík 2', 'Reykjavík 3', 'Reykjavík 4', 'Reykjavík 5', 'Reykjavík 6', 'Reykjavík 7', 'Reykjavík 8', 'Reykjavík 9', 'Reykjavík 10', 'Seltjarnarnes', 'Mosfellsbær', 'Kjalarnes', 'Akranes', 'Borgarnes', 'Hvanneyri', 'Reykholt í Borgarfirði', 'Staður', 'Hólmavík', 'Drangsnes', 'Búðardalur', 'Patreksfjörður', 'Tálknafjörður', 'Bíldudalur', 'Ísafjörður', 'Hnífsdalur', 'Bolungarvík', 'Suðureyri', 'Súðavík', 'Flateyri', 'Þingeyri', 'Stykkishólmur', 'Grundarfjörður', 'Hellissandur', 'Ólafsvík', 'Snæfellsbær', 'Hvammstangi', 'Blönduós', 'Skagaströnd', 'Sauðárkrókur', 'Varmahlíð', 'Hofsós og Hólar', 'Akureyri 1', 'Akureyri 2', 'Akureyri 3', 'Siglufjörður', 'Dalvík', 'Ólafsfjörður', 'Akureyri 5', 'Grenivík', 'Húsavík', 'Laugar', 'Mývatn', 'Kópasker', 'Raufarhöfn', 'Þórshöfn', 'Egilsstaðir 1', 'Egilsstaðir 2', 'Seyðisfjörður', 'Borgarfjörður eystri', 'Vopnafjörður', 'Eskifjörður', 'Neskaupstaður', 'Reyðarfjörður', 'Fáskrúðsfjörður', 'Stöðvarfjörður', 'Breiðdalsvík', 'Djúpivogur', 'Höfn í Hornafirði 1', 'Höfn í Hornafirði 2', 'Öræfi', 'Kirkjubæjarklaustur', 'Vík í Mýrdal', 'Hvolsvöllur', 'Hella', 'Flúðir', 'Laugarvatn', 'Þorlákshöfn', 'Eyrarbakki og Stokkseyri', 'Hveragerði', 'Selfoss 1', 'Selfoss 2', 'Selfoss 4', 'Selfoss 5', 'Vestmannaeyjar', 'Vogar', 'Grindavík', 'Reykjanesbær 1', 'Reykjanesbær 2', 'Sandgerði', 'Garður', 'Hafnarfjörður 1', 'Hafnarfjörður 2', 'Garðabær', 'Bessastaðahreppur', 'Kópavogur 1', 'Kópavogur 2', 'Kópavogur 3' ];

export const DATES = ['20201219', '20201220'];
export const HOURS = [10, 11, 12, 13, 14, 15, 16, 17];
export const MINUTES = [0, 15, 30, 45];

export const MAIN_APP_HOST = "https://listagjof.listahatid.is";

export const MAPBOX_COUNTRY_CODE = 'IS';
export const MAPBOX_REGION_PLACE_NAME = 'IS ';
